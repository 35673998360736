import React from 'react'
import { Card, CardBody, Form, FormGroup, Label } from 'reactstrap'
import FalconCardHeader from "../common/FalconCardHeader"
import chargeSample from '../../assets/pdf/重要事項説明書（契約締結後）_【ゼロエミプラン】.pdf';

const Clause = () => {
  return (
    <Card className="mt-3">
      <FalconCardHeader title="ご契約約款等" light={false} />
      <CardBody className="bg-light border-top">
        <Form>
          <FormGroup>
            <Label for="exampleName">電気供給約款</Label>
            <a className="nav-link ml-3" href="https://aichi-denryoku.jp/aichi_denryoku_wp/wp-content/themes/aichi_denryoku/assets/pdf/agreement.pdf" target="_blank" rel="noopener noreferrer">
              電気供給約款【低 圧】
            </a>
          </FormGroup>
          <FormGroup>
            <Label for="exampleName">重要事項説明</Label>
            <a className="nav-link ml-3" href={chargeSample} target="_blank" rel="noopener noreferrer">
              重要事項説明（契約締結後）【ゼロエミプラン】
            </a>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

export default Clause
