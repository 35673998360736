import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from "../common/FalconCardHeader";
import Loader from '../common/Loader';

const Profile = () => {
  return (
    <ContentWithAsideLayout />
  );
};


const CustomerDetailRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col xs={5}>{children}</Col>
  </Row>
);

const ContentWithAsideLayout = () => {
  const [customer, setCustomer] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/customer').then(response => {
      console.log(response)
      setCustomer(response.data)
      setLoading(false)
    }).catch((response) => {
      console.log(response)
    })
  }, [])

  return (
    <>
      {loading ? (
        <Card className="mb-3 mt-3">
          <FalconCardHeader title="お客様情報" light={false} />
          <CardBody className="bg-light border-top">
            <Loader />
          </CardBody>
        </Card>
      ) : (
        <>
          <Card className="mb-3 mt-3">
            <FalconCardHeader title="お客様情報" light={false} />
            <CardBody className="bg-light border-top">
              <Row>
                <Col lg className="col-xxl-5">
                  <CustomerDetailRow title="お客様番号">{customer.uuid}</CustomerDetailRow>
                  <CustomerDetailRow title="お客様名">{customer.user?.name}</CustomerDetailRow>
                  <CustomerDetailRow title="フリガナ">{customer.kana}</CustomerDetailRow>
                  <CustomerDetailRow title="電話">{customer.phone}</CustomerDetailRow>
                  <CustomerDetailRow title="Ｅｍａｉｌ">{customer.user?.email}</CustomerDetailRow>
                  <CustomerDetailRow title="お客様ステータス">{customer.customer_status?.name}</CustomerDetailRow>
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <CustomerDetailRow title="郵便番号">〒 {customer.postcode}</CustomerDetailRow>
                  <CustomerDetailRow title="住所">{customer.address}</CustomerDetailRow>
                  <CustomerDetailRow title="建物・マンション名">{customer.building}</CustomerDetailRow>
                  {/* <CustomerDetailRow title="GMO PAYMENT会員ID">{customer.gmo_id}</CustomerDetailRow> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <FalconCardHeader title="ご契約情報" light={false}>
            </FalconCardHeader>
            {customer.contracts?.map(
              (contract, index) => (
                <CardBody key={index} className="bg-light border-top">
                  <Row>
                    <Col lg className="col-xxl-5">
                      <CustomerDetailRow title="施設名">{contract.name}</CustomerDetailRow>
                      <CustomerDetailRow title="フリガナ">{contract.kana}</CustomerDetailRow>
                      <CustomerDetailRow title="供給地点特定番号">{contract.uuid}</CustomerDetailRow>
                      <CustomerDetailRow title="ご契約メニュー">{contract.menu}</CustomerDetailRow>
                      <CustomerDetailRow title="ご利用開始日">{contract.start}</CustomerDetailRow>
                      <CustomerDetailRow title="ご契約終了日">{contract.end}</CustomerDetailRow>
                      <CustomerDetailRow title="ご契約ステータス">{contract.contract_status?.name}</CustomerDetailRow>
                    </Col>
                    <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                      <CustomerDetailRow title="需要場所">{contract.address}</CustomerDetailRow>
                      <CustomerDetailRow title="エリア">{contract.area?.name}</CustomerDetailRow>
                      <CustomerDetailRow title="ご契約種別">{contract.contract_type?.name}</CustomerDetailRow>
                      <CustomerDetailRow title="ご契約形態">{contract.contract_style?.name}</CustomerDetailRow>
                      {/* <CustomerDetailRow title="ご契約容量値">{contract.capacity}</CustomerDetailRow> */}
                      {/* <CustomerDetailRow title="ご契約容量単位">{contract.contract_unit?.name}</CustomerDetailRow> */}
                    </Col>
                  </Row>
                </CardBody>
              )
            )}
          </Card>
        </>
      )}
    </>
  );
};


export default Profile;
